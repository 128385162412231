import React from "react"
import LayoutComponent from "../../../components/documentation-layout/Layout"
import { Col, Row } from "react-bootstrap"

const jsonData = `{
  "testcases": [
    {
      "testcaseKey": "TEST_CASE_KEY",
      "status": "Pass/Failed",
      "executionTime": 25
    },
    {
      "testcaseKey": "TEST_CASE_KEY",
      "status": "Pass/Failed",
      "executionTime": 101
    }
  ],
  "iteration": "Iteration 1",
  "testcycle": "YOUR_TEST_CYCLE_KEY"
}`
const UpdateExecutionStatus = () => {
  return (
    <LayoutComponent>
      <h3>Update Execution Status via GAINSS API</h3>
      <p>
        The GAINSS API allows you to update the execution status of your test
        cases. This endpoint is publicly accessible and can be used outside of
        the GAINSS platform. Follow the guidelines below to utilize this API
        effectively:
      </p>

      <Row>
        <Col>
          <p>
            <strong>GAINSS API URL:</strong> https://plugin.gtentechnologies.com
          </p>
          <p>
            <strong>Endpoint:</strong> /api/v1/plugin/update-status
          </p>
          <p>
            <strong>Method:</strong> POST
          </p>
          <p>
            <strong>Headers:</strong>
          </p>
          <ul>
            <li>
              <strong>Authorization:</strong> Bearer {`{GAINSS_API_TOKEN}`}
            </li>
            <li>
              <strong>Content-Type:</strong> application/json
            </li>
          </ul>
          <p>
            <strong>Errors:</strong>{" "}
          </p>
          <ul className="list-unstyled">
            <li>
              <strong>400:</strong> Bad Request
            </li>
            <li>
              <strong>401:</strong> Unauthorized
            </li>
            <li>
              <strong>403:</strong> Forbidden
            </li>
            <li>
              <strong>404:</strong> Not Found. If test cycle, iteration or
              testcase doesn't exist
            </li>
            <li>
              <strong>500:</strong> Internal Server Error
            </li>
          </ul>
        </Col>
        <Col>
          <div className="code-panel">
            <h3>Request Payload</h3>
            <pre>
              <code>{jsonData}</code>
            </pre>
          </div>
          <ul>
            <li>
              <strong>testcases:</strong> This field can include multiple test
              cases at a time. Each test case must contain:
              <ul>
                <li>
                  <strong>testcaseKey:</strong> The key for each test case
                  should match the key displayed on the execution screen for
                  every test case under an iteration.
                </li>
                <li>
                  <strong>status:</strong> This should be one of the following
                  values: <strong>Pass</strong>, <strong>Fail</strong>,{" "}
                  <strong>Blocked</strong>, <strong>In Progress</strong>, or{" "}
                  <strong>Not Executed</strong>. Alternatively, you can use any
                  custom execution status name configured on the{" "}
                  <strong>Configuration</strong> page in the GAINSS plugin.
                </li>
                <li>
                  <strong>executionTime:</strong> This optional field represents
                  the actual execution time of the test case, measured in{" "}
                  <strong>milliseconds (ms)</strong>.
                </li>
              </ul>
            </li>
            <li>
              <strong>iteration:</strong> This should correspond with the{" "}
              <strong>iteration</strong> name shown in the Test Cycle on the
              left panel of the execution screen in the GAINSS plugin.
            </li>
            <li>
              <strong>testcycle:</strong> This should match the test cycle key.
            </li>
          </ul>
        </Col>
      </Row>
    </LayoutComponent>
  )
}

export default UpdateExecutionStatus
